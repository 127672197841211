import React, { useEffect, useMemo, useState } from "react";
import "./CredentialManagement.scss";
import { Button } from "@material-ui/core";
import { Modal } from "react-bootstrap";
import { Input, Label } from "reactstrap";
import moment from "moment";
import { update } from "lodash";
import Select from "react-select";
import "./CredentialManagement.scss";
import { ApiGet } from "../../../helpers/API/ApiData";
import { toast } from "react-toastify";
import { setIn } from "formik";
import MailIcon from "../../../assets/icon/main-md.svg";
import addressIcon from "../../../assets/icon/addressIcon.svg";
import prIcon from "../../../assets/icon/prIcon.svg";
import phoneIcon from "../../../assets/icon/phoneIcon.svg";
import BarcodeIcon from "../../../assets/icon/barcode.png";
import Logo from "../../../assets/images/child-logo.svg";
import profileimage from "../../../assets/images/profile_image.png";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

function AddUserDetails(props) {
  const [inputType, setInputType] = useState(false);
  const [selectedProject, setSelectedProject] = useState([]);

  const [pdfProfile, setPdfProfile] = useState("");
  const {
    inputValue,
    handleChange,
    errors,
    formType,
    handleAddNews,
    buttonLoading,
    getNewsData,
    uploadImage,
    setUploadImage,
    loading,
    handleOnClose,
    setInputValue,
    setErrors,
    setChecked,
    checked,
    salesPatner,
    setSalesPatner,
    selectedPatner,
    setSelectedSalesPatner,
    setVattenfallChecked,
    vattenfallChecked,
    setVattenfallSalesPartner,
    VattenfallsalesPatner,
    selectedVattenfallPartner,
    setSelectedVattenfallPartner,
    allowLead,
    setAllowLead,
    setTeamLeadCheck,
    teamLeadCheck,
    projectName,
    setProjectOptions,
  } = props;

  console.log("projectName", projectName);

  useEffect(() => {
    const handleGetSalesPatner = async () => {
      try {
        const response = await ApiGet(`user/get`);
        const data = response?.data?.payload?.data;

        if (data && Array.isArray(data)) {
          const optionData = data.map((item) => ({
            value: item?._id,
            label: item?.name,
          }));
          setSalesPatner(optionData);
          setVattenfallSalesPartner(optionData);
        }
      } catch (error) {
        toast.error("Vertriebspartner konnten nicht abgerufen werden..");
      }
    };

    const handleGetProject = async () => {
      await ApiGet(`project/get`)
        .then((response) => {
          const data = response?.data?.payload.data;

          // const filterdData = data?.filter;
          const optionData = data?.map((item) => ({
            value: item._id,
            label: item.projectname,
          }));
          setProjectOptions(optionData);
        })
        .catch((error) => {
          toast.error(error);
        });
    };
    handleGetSalesPatner();
    handleGetProject();
  }, []);

  const handleImagesss = (event) => {
    const file = event.target.files[0];
    setInputValue({ ...inputValue, profileImage: file });
    setUploadImage(URL.createObjectURL(file));
  };

  useEffect(() => {
    if (formType == "update" && projectName) {
      const projectArray = inputValue?.projectName?.map((item) => item.label);
      console.log("projectArrayprojectArray", projectArray, projectName);
      const updatedData = projectName?.filter((ele) => {
        if (
          projectArray?.includes(ele.label)
          // inputValue?.projectName?.toLowerCase() === ele.label.toLowerCase()
        ) {
          return ele;
        }
      });
      setSelectedProject(updatedData);
      // const ProjectData = salesPatner?.map((ele) => {
      //   if (inputValue?.patners?.includes(ele.label)) {
      //     return ele;
      //   }
      // });

      const filterdData = inputValue?.patners?.map((item) => item);
      setSelectedSalesPatner(filterdData);
      const newPartnerDta = inputValue?.vattenfallPatners?.map((item) => item);

      setSelectedVattenfallPartner(newPartnerDta);
    }
  }, [projectName, salesPatner]);

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      maxHeight: "100px",
      width: "200px",
      minHeight: "unset",
      overflow: "auto",
    }),
  };

  const handleOnChange = (selectedPatner) => {
    setSelectedSalesPatner(selectedPatner);
    if (selectedPatner && selectedPatner.length > 0) {
      setInputValue({ ...inputValue, patners: selectedPatner });
    } else {
      setInputValue({ ...inputValue, patners: [] });
    }
  };

  const handleOnNewChange = (selectedPatner) => {
    setSelectedVattenfallPartner(selectedPatner);
    if (selectedPatner && selectedPatner.length > 0) {
      setInputValue({ ...inputValue, vattenfallPatners: selectedPatner });
    } else {
      setInputValue({ ...inputValue, vattenfallPatners: [] });
    }
  };

  const bindInput = (value) => {
    var regex = new RegExp("^[^0-9]*$");
    var key = String.fromCharCode(
      !value.charCode ? value.which : value.charCode
    );
    if (regex.test(key)) {
      value.preventDefault();
      return false;
    }
  };

  const handleOnChanges = (selectedProject) => {
    console.log("selectedProject", selectedProject);
    setSelectedProject(selectedProject);
    setInputValue({ ...inputValue, projectName: selectedProject });
    setErrors({ ...errors, projectName: "" });
  };

  const downloadPdf = () => {
    const input = document.getElementById("pdf-container");
    const pdfWidth = 500;
    const pdfHeight = 300;
    const scale = 3;
    html2canvas(input, { scale: scale }).then((canvas) => {
      const pdf = new jsPDF("l", "mm", "a4");
      const ratio = canvas.height / canvas.width;
      let pdfWidthActual = pdfWidth;
      let pdfHeightActual = pdfHeight;

      if (ratio < 1) {
        pdfHeightActual = pdfWidth * ratio;
      } else {
        pdfWidthActual = pdfHeight / ratio;
      }
      pdf.addImage(
        canvas.toDataURL("image/png", 1.0),
        "PNG",
        0,
        0,
        pdfWidthActual,
        pdfHeightActual
      );

      pdf.save(`${inputValue?.name?.trim() || "document"}.pdf`);
    });
  };

  useEffect(() => {
    if (inputValue?.profileImage) {
      fetch(inputValue?.profileImage)
        .then((response) => response.blob())
        .then((blob) => {
          const url = URL.createObjectURL(blob);
          setPdfProfile(url);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [inputValue?.profileImage]);

  return (
    <div className="modal-content new-model-conent-sye">
      <Modal.Header
        className="justify-content-start d-flex align-items-center"
        closeButton
      >
        <Modal.Title style={{ color: "#41624C" }}>
          {formType == "add"
            ? "Vertriebspartner hinzufügen"
            : "Vertriebspartner aktualisieren"}{" "}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div class="row pb-3">
          <div className="col-4 col-md-4">
            <Label className="form-label" for="name">
              Benutzername <span className="text-danger">*</span>
            </Label>
            <Input
              placeholder="Benutzername"
              name="name"
              value={inputValue?.name}
              onChange={(event) => handleChange(event, "input")}
              invalid={errors?.name && "true"}
            />
            <span className="errors">{errors?.name}</span>
          </div>
          <div className="col-4 col-md-4">
            <Label className="form-label" for="Vorname">
              Vorname
            </Label>
            <Input
              placeholder="Vorname"
              name="vorname"
              value={inputValue?.vorname}
              onChange={(event) => handleChange(event, "input")}
              invalid={errors?.vorname && "true"}
            />
          </div>
          <div className="col-4 col-md-4">
            <Label className="form-label" for="Nachname">
              Nachname
            </Label>
            <Input
              placeholder="Nachname"
              name="nachname"
              value={inputValue?.nachname}
              onChange={(event) => handleChange(event, "input")}
              invalid={errors?.nachname && "true"}
            />
          </div>
        </div>
        <div class="row pb-3">
          <div className="col-4 col-md-4">
            <Label className="form-label" for="name">
              Vertriebspartner
            </Label>
            <div>
              <div>
                <Select
                  className="dropdown-align "
                  value={selectedPatner}
                  onChange={handleOnChange}
                  options={salesPatner}
                  isMulti
                  maxHeight={"100px"}
                  styles={customStyles}
                  defaultValue={selectedPatner}
                />
              </div>
            </div>
          </div>
          <div className="col-4 col-md-4">
            <Label className="form-label" for="name">
              E-mail <span className="text-danger">*</span>
            </Label>
            <Input
              type="email"
              placeholder="E-mail"
              name="email"
              value={inputValue?.email}
              onChange={(event) => handleChange(event, "input")}
              invalid={errors?.email && "true"}
            />
            <span className="errors">{errors?.email}</span>
          </div>
          <div className="col-4 col-md-4">
            <Label className="form-label" for="name">
              {formType == "add" ? "Passwort" : "Neues Kennwort"}
              {formType == "add" && <span className="text-danger">*</span>}
            </Label>
            <div className="eyeIconContainer">
              <Input
                type={!inputType ? "password" : "text"}
                placeholder="Passwort"
                name="password"
                value={inputValue?.password}
                onChange={(event) => handleChange(event, "input")}
                invalid={errors?.password && "true"}
              />
              <i
                onClick={() => setInputType(!inputType)}
                className={
                  inputType ? "eyeIcon fa fa-eye-slash" : "eyeIcon fa fa-eye"
                }
                aria-hidden="true"
              ></i>
            </div>
            {formType == "add" && (
              <span className="errors">{errors?.password}</span>
            )}
          </div>
        </div>
        <div className="row pb-3">
          <div className="col-4 col-md-4">
            <Label className="form-label" for="name">
              Projektname <span className="text-danger">*</span>
            </Label>
            <div>
              <Select
                className="dropdown-align "
                value={selectedProject}
                onChange={(e) => handleOnChanges(e)}
                options={projectName}
                isMulti
                maxHeight={"100px"}
                styles={customStyles}
                defaultValue={selectedProject}
              />
            </div>
            <span className="errors">{errors?.projectName}</span>
          </div>
          <div className="col-4 col-md-4">
            <Label className="form-label" for="Vorname">
              Vergütung/ Lead <span className="text-danger">*</span>
            </Label>
            <Input
              placeholder="Vergütung/ Lead"
              name="compensation_Lead"
              value={inputValue?.compensation_Lead}
              onChange={(event) => handleChange(event, "input")}
              invalid={errors?.compensation_Lead && "true"}
              onKeyPress={bindInput}
            />
            <span className="errors">{errors?.compensation_Lead}</span>
          </div>

          <div className="col-4 col-md-4">
            <Label className="form-label" for="Vorname">
              Vergütung/ Team <span className="text-danger">*</span>
            </Label>
            <Input
              placeholder="Vergütung/ Team"
              name="compensation_Team"
              value={inputValue?.compensation_Team}
              onChange={(event) => handleChange(event, "input")}
              invalid={errors?.compensation_Team && "true"}
              onKeyPress={bindInput}
            />
            <span className="errors">{errors?.compensation_Team}</span>
          </div>
        </div>
        <div className="row pb-3">
          <div className="col-4 col-md-4">
            <Label className="form-label" for="name">
              Unique Tracking Id <span className="text-danger">*</span>
            </Label>
            <Input
              type="text"
              placeholder="Unique Tracking Id"
              name="uniqueTrackingId"
              value={inputValue?.uniqueTrackingId}
              onChange={(event) => handleChange(event, "input")}
              invalid={errors?.uniqueTrackingId && "true"}
            />
            <span className="errors">{errors?.uniqueTrackingId}</span>
          </div>
          <div className="col-4 col-md-4">
            <Label className="form-label" for="name">
              Monatsziel
            </Label>
            <Input
              type="text"
              placeholder="Monatsziel"
              name="userLead"
              onKeyPress={bindInput}
              value={inputValue?.userLead}
              onChange={(event) => handleChange(event, "input")}
              invalid={errors?.userLead && "true"}
            />
            <span className="errors">{errors?.userLead}</span>
          </div>
          <div className="col-4 col-md-4">
            <Label className="form-label" for="name">
              Geburtstag
            </Label>
            <Input
              type="date"
              placeholder=""
              name="birthday"
              value={inputValue?.birthday}
              onChange={(event) => handleChange(event, "input")}
              invalid={errors?.birthday && "true"}
            />
            <span className="errors">{errors?.birthday}</span>
          </div>
        </div>
        <div className="row pb-3">
          <div className="col-4 col-md-4">
            <Label className="form-label" for="name">
              IBAN
            </Label>
            <Input
              type="text"
              placeholder="IBAN"
              name="iban"
              value={inputValue?.iban}
              onChange={(event) => handleChange(event, "input")}
              invalid={errors?.iban && "true"}
            />
            <span className="errors">{errors?.iban}</span>
          </div>

          <div className="col-4 col-md-4">
            <Label className="form-label" for="name">
              Telefon
            </Label>
            <Input
              type="text"
              placeholder="Telefon"
              name="phone"
              onKeyPress={bindInput}
              value={inputValue?.phone}
              onChange={(event) => handleChange(event, "input")}
              invalid={errors?.phone && "true"}
            />
            <span className="errors">{errors?.phone}</span>
          </div>
          <div className="col-4 col-md-4">
            <Label className="form-label" for="name">
              Straße und Hausnummer
            </Label>
            <Input
              type="text"
              placeholder="Straße und Hausnummer"
              name="streetHouseNumber"
              value={inputValue?.streetHouseNumber}
              onChange={(event) => handleChange(event, "input")}
              invalid={errors?.streetHouseNumber && "true"}
            />
            <span className="errors">{errors?.streetHouseNumber}</span>
          </div>
        </div>
        <div className="row pb-3">
          <div className="col-4 col-md-4">
            <Label className="form-label" for="name">
              Kreditinstitut
            </Label>
            <Input
              type="text"
              placeholder="Kreditinstitut"
              name="creditInstitution"
              value={inputValue?.creditInstitution}
              onChange={(event) => handleChange(event, "input")}
              invalid={errors?.creditInstitution && "true"}
            />
            <span className="errors">{errors?.creditInstitution}</span>
          </div>
          <div className="col-4 col-md-4">
            <Label className="form-label" for="name">
              Postleitzahl
            </Label>
            <Input
              type="text"
              placeholder="Postleitzahl"
              name="postalCode"
              onKeyPress={bindInput}
              value={inputValue?.postalCode}
              onChange={(event) => handleChange(event, "input")}
              invalid={errors?.postalCode && "true"}
            />
            <span className="errors">{errors?.postalCode}</span>
          </div>
          <div className="col-4 col-md-4">
            <Label className="form-label" for="name">
              Ort
            </Label>
            <Input
              type="text"
              placeholder="Ort"
              name="location"
              onKeyPress={bindInput}
              value={inputValue?.location}
              onChange={(event) => handleChange(event, "input")}
              invalid={errors?.location && "true"}
            />
            <span className="errors">{errors?.location}</span>
          </div>
        </div>
        <div className="row pb-3">
          <div className="col-4 col-md-4">
            <Label className="form-label" for="name">
              Profilbild
            </Label>
            <Input
              type="file"
              placeholder="profileImage"
              name="profileImage"
              onChange={(event) => handleImagesss(event)}
              invalid={errors?.profileImage && "true"}
            />
          </div>
          <div className="col-4 col-md-4">
            <Label className="form-label" for="name">
              Kontoinhaber
            </Label>
            <Input
              type="text"
              placeholder="Kontoinhaber"
              name="accountOwner"
              value={inputValue?.accountOwner}
              onChange={(event) => handleChange(event, "input")}
              invalid={errors?.accountOwner && "true"}
            />
            <span className="errors">{errors?.accountOwner}</span>
          </div>
          <div className="col-4 col-md-4">
            <Label className="form-label" for="name">
              Unterschrift
            </Label>
            <Input
              type="file"
              placeholder="IBAN"
              name="signature"
              onChange={(event) => handleChange(event, "file")}
              invalid={errors?.signature && "true"}
            />
            <span className="errors">{errors?.signature}</span>
          </div>
        </div>
        <div className="row pb-3 modal-steurnummer-alignment-mobile">
          <div className="col-4 col-md-4">
            <Label className="form-label" for="name">
              Steuernummer
            </Label>
            <Input
              type="text"
              placeholder="Steuernummer"
              value={inputValue?.steuernummer}
              name="steuernummer"
              onChange={(event) => handleChange(event, "input")}
              invalid={errors?.signature && "true"}
            />
          </div>
          <div className="col-4 col-md-4">
            <Label className="form-label">Geschlecht</Label>
            <div className="new-radiobutton">
              <Label
                check
                style={{ fontSize: "16px" }}
                className="radio-button-des"
              >
                <Input
                  type="radio"
                  name="gender"
                  value="Männlich"
                  onClick={(event) => handleChange(event, "input")}
                />
                Männlich
              </Label>

              <Label check style={{ fontSize: "16px" }}>
                <Input
                  type="radio"
                  name="gender"
                  value="Weiblich"
                  onChange={(event) => handleChange(event, "input")}
                />
                Weiblich
              </Label>
            </div>

            <span className="errors">{errors?.accountOwner}</span>
          </div>
          <div className="checkbox-aligment-main-mobile">
            <div style={{ display: "flex", alignItems: "center" }}>
              <input
                type="checkbox"
                style={{ marginBottom: "10px", cursor: "pointer" }}
                checked={checked}
                onChange={(e) => setChecked(e.target.checked)}
              />{" "}
              <Label className="form-label" style={{ paddingLeft: "10px" }}>
                PV Vertrieb
              </Label>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <input
                type="checkbox"
                style={{ marginBottom: "10px", cursor: "pointer" }}
                checked={allowLead}
                onChange={(e) => setAllowLead(e.target.checked)}
              />{" "}
              <Label className="form-label" style={{ paddingLeft: "10px" }}>
                Leads
              </Label>
            </div>
          </div>
          <div
            className="col-2 col-md-2 checkbox-aligment-main-web"
            style={{ display: "flex", alignItems: "center" }}
          >
            <input
              type="checkbox"
              style={{ marginBottom: "10px", cursor: "pointer" }}
              checked={checked}
              onChange={(e) => setChecked(e.target.checked)}
            />{" "}
            <Label className="form-label" style={{ paddingLeft: "10px" }}>
              PV Vertrieb
            </Label>
          </div>
          <div
            className="col-2 col-md-2 checkbox-aligment-main-web"
            style={{ display: "flex", alignItems: "center" }}
          >
            <input
              type="checkbox"
              style={{ marginBottom: "10px", cursor: "pointer" }}
              checked={allowLead}
              onChange={(e) => setAllowLead(e.target.checked)}
            />{" "}
            <Label className="form-label" style={{ paddingLeft: "10px" }}>
              Leads
            </Label>
          </div>
        </div>

        <div className="row pb-3">
          <div
            className="col-4 col-md-4"
            style={{ display: "flex", alignItems: "center" }}
          >
            <input
              type="checkbox"
              style={{ marginBottom: "10px" }}
              checked={vattenfallChecked}
              onChange={(e) => setVattenfallChecked(e.target.checked)}
            />{" "}
            <Label className="form-label" style={{ paddingLeft: "10px" }}>
              Vattenfall Vertriebspartner
            </Label>
          </div>

          {vattenfallChecked && (
            <div className="col-4 col-md-4">
              <Label className="form-label" for="name">
                Vattenfall Vertriebspartner
              </Label>
              <div>
                <div>
                  <Select
                    className="dropdown-align "
                    value={selectedVattenfallPartner}
                    onChange={handleOnNewChange}
                    options={VattenfallsalesPatner}
                    isMulti
                    maxHeight={"100px"}
                    styles={customStyles}
                    defaultValue={selectedVattenfallPartner}
                  />
                </div>
              </div>
            </div>
          )}

          <div
            className="col-4 col-md-4"
            style={{ display: "flex", alignItems: "center" }}
          >
            <input
              type="checkbox"
              style={{ marginBottom: "10px" }}
              checked={teamLeadCheck}
              onChange={(e) => setTeamLeadCheck(e.target.checked)}
            />{" "}
            <Label className="form-label" style={{ paddingLeft: "10px" }}>
              D2D'ler / Teamleite
            </Label>
          </div>
        </div>
        <div className="image-position" onClick={downloadPdf}>
          <i class="fa-solid fa-download" style={{ fontSize: "20px" }}></i>
        </div>
        <div className="profile-child-box" id="pdf-container">
          <div className="imagebox">
            <div>
              <div className="relative-section">
                <img
                  src={
                    formType === "update"
                      ? uploadImage
                        ? uploadImage
                        : inputValue?.profileImage !== "null" ||
                          !inputValue?.profileImage
                        ? pdfProfile
                        : profileimage
                      : uploadImage
                      ? uploadImage
                      : profileimage
                  }
                  width="100px"
                  height="100px"
                />
                <div className="liner"></div>
              </div>
              <p>{inputValue.name}</p>
              <span>Regionaler Vertrieb</span>
            </div>
            <div className="right-sider">
              <a>
                <svg
                  width="10"
                  height="10"
                  viewBox="0 0 50 34"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.46094 30.8486V3.15625L22.3071 30.8486V3.15625M33.0763 30.8486H46.5379M33.0763 11.4639C33.0763 13.6673 33.7855 15.7804 35.0477 17.3384C36.31 18.8964 38.022 19.7716 39.8071 19.7716C41.5922 19.7716 43.3042 18.8964 44.5665 17.3384C45.8287 15.7804 46.5379 13.6673 46.5379 11.4639C46.5379 9.26061 45.8287 7.14751 44.5665 5.58952C43.3042 4.03152 41.5922 3.15625 39.8071 3.15625C38.022 3.15625 36.31 4.03152 35.0477 5.58952C33.7855 7.14751 33.0763 9.26061 33.0763 11.4639Z"
                    stroke="white"
                    stroke-width="5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </a>
              <a>
                <svg
                  width="10"
                  height="10"
                  viewBox="0 0 50 50"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M49.6 16.4051L49.375 15.5051C49.0748 14.6318 48.6808 13.7936 48.2 13.0051C47.1023 11.1722 45.5304 9.6694 43.65 8.65511L30.525 1.55511C28.7894 0.631488 26.8535 0.148438 24.8875 0.148438C22.9215 0.148438 20.9856 0.631488 19.25 1.55511L6.125 8.65511C4.24463 9.6694 2.67274 11.1722 1.575 13.0051C1.08688 13.7861 0.700421 14.6262 0.425 15.5051V15.8551C0.14561 16.8563 0.00265186 17.8907 0 18.9301V37.9801C0.0066053 41.1275 1.25984 44.1441 3.4854 46.3697C5.71097 48.5953 8.72758 49.8485 11.875 49.8551H38.125C39.6845 49.8551 41.2286 49.5479 42.6694 48.9512C44.1101 48.3544 45.4192 47.4797 46.5219 46.377C47.6246 45.2743 48.4993 43.9652 49.0961 42.5245C49.6928 41.0837 50 39.5396 50 37.9801V18.9051C49.9554 18.06 49.8213 17.222 49.6 16.4051ZM28.95 24.7801C27.7108 25.4812 26.3113 25.8497 24.8875 25.8497C23.4637 25.8497 22.0642 25.4812 20.825 24.7801L4.5 15.3301C4.575 15.1401 4.6675 14.9551 4.775 14.7801C5.52951 13.5249 6.60925 12.497 7.9 11.8051L21.025 4.70511C22.206 4.06166 23.5301 3.72633 24.875 3.73011C26.2278 3.72642 27.56 4.06162 28.75 4.70511L41.875 11.8051C43.4194 12.64 44.6485 13.9569 45.375 15.5551L28.95 24.7801Z"
                    fill="white"
                  />
                </svg>
              </a>
              <a>
                <svg
                  width="10"
                  height="10"
                  viewBox="0 0 53 60"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M0.960938 25.984C0.96409 19.0908 3.67952 12.4811 8.51009 7.6084C13.3407 2.73568 19.8908 -0.00106108 26.7202 3.08612e-07C40.9433 3.08612e-07 52.4795 11.636 52.4795 25.984C52.4795 36.68 47.177 45.156 41.4941 50.884C38.982 53.4306 36.1666 55.6529 33.1125 57.5C31.8364 58.26 30.6435 58.86 29.6052 59.268C28.6264 59.66 27.6 59.964 26.7202 59.964C25.8404 59.964 24.814 59.66 23.8352 59.268C22.623 58.7701 21.4505 58.179 20.3279 57.5C17.2739 55.6528 14.4585 53.4305 11.9463 50.884C6.26338 45.156 0.960938 36.68 0.960938 25.984ZM26.7202 15.988C24.0926 15.988 21.5726 17.0416 19.7146 18.9169C17.8566 20.7923 16.8128 23.3358 16.8128 25.988C16.8128 28.6402 17.8566 31.1837 19.7146 33.0591C21.5726 34.9344 24.0926 35.988 26.7202 35.988C29.3478 35.988 31.8678 34.9344 33.7258 33.0591C35.5838 31.1837 36.6276 28.6402 36.6276 25.988C36.6276 23.3358 35.5838 20.7923 33.7258 18.9169C31.8678 17.0416 29.3478 15.988 26.7202 15.988Z"
                    fill="white"
                  />
                </svg>
              </a>
              <a>
                <svg
                  width="10"
                  height="10"
                  viewBox="0 0 46 44"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M31.6952 27.804L30.5683 28.9365C30.5683 28.9365 27.8858 31.6265 20.5667 24.2815C13.2476 16.9365 15.9301 14.2465 15.9301 14.2465L16.6384 13.5315C18.3896 11.7765 18.5555 8.95651 17.0273 6.89651L13.9065 2.68901C12.0142 0.139011 8.3608 -0.198488 6.19356 1.97651L2.3049 5.87651C1.23242 6.95651 0.514134 8.35151 0.600824 9.90151C0.823741 13.869 2.60212 22.4015 12.5194 32.3565C23.0386 42.9115 32.9089 43.3315 36.9437 42.9515C38.2217 42.8315 39.3314 42.1765 40.2255 41.2765L43.7426 37.7465C46.1204 35.364 45.4517 31.2765 42.4101 29.609L37.6793 27.0115C35.683 25.919 33.2556 26.239 31.6952 27.804Z"
                    fill="white"
                  />
                </svg>
              </a>
            </div>
          </div>
          <div className="text-box" style={{ width: "270px" }}>
            <div className="child-log">
              <img src={Logo} alt="Logo" />
            </div>
            <div className="text-grid">
              <p>P.NR.</p>
              <span>
                <a>:</a> <a>{inputValue?.userId}</a>
              </span>
            </div>
            <div className="text-grid">
              <p>E-mail</p>
              <span>
                <a>: </a> <a>info@wepro-deutschland.de</a>
              </span>
            </div>
            <div className="text-grid">
              <p>Addresse</p>
              <span>
                <a>:</a>
                <a> Alsterufer 20 in 20354 Hamburg</a>
              </span>
            </div>
            <div className="text-grid">
              <p>Telefon</p>
              <span>
                <a>:</a> <a>+49 172 44 65 322</a>
              </span>
            </div>
            <div className="barcode-alignment">
              <img
                src={BarcodeIcon}
                alt="BarcodeIcon"
                style={{ width: "100%" }}
              />
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="activebutton h-40px"
          onClick={handleOnClose}
          style={{ marginRight: "15px" }}
        >
          Abbrechen
        </Button>
        <Button
          disabled={loading}
          className="addbutton"
          onClick={handleAddNews}
        >
          <span>{formType == "add" ? " Speichern" : "update"}</span>
          {(buttonLoading || loading) && (
            <div class="h-20px spinner-border text-light w-20px ml-2"></div>
          )}
        </Button>
      </Modal.Footer>
    </div>
  );
}

export default AddUserDetails;
