import React, { useState } from "react";
import "./leadmodel.scss";
import { TailSpin } from "react-loader-spinner";
import { ApiPut } from "../../../helpers/API/ApiData";
import { toast } from "react-toastify";
const LeadNotesModel = ({
  singleLeadNote,
  setLeadNotesModel,
  leadShow,
  setLeadShow,
  setSingleLeadNote,
  notizenArray,
  leadNotesArray,
  id,
  getDataById,
  modalType,
  setModalType,
}) => {
  const [loading, setLoading] = useState(false);

  const updateLeadNotes = async () => {
    setLoading(true);
    let body;
    if (modalType === "leadNotes") {
      const filterdLeadNote = leadNotesArray?.map((item) => {
        if (item?._id == singleLeadNote?._id) {
          singleLeadNote.updatedAt = new Date();
          return singleLeadNote;
        } else {
          return item;
        }
      });
      body = {
        notizen: notizenArray,
        leadNotes: filterdLeadNote,
      };
    }
    if (modalType === "notizen") {
      const filterdLeadNote = notizenArray?.map((item) => {
        if (item?._id == singleLeadNote?._id) {
          singleLeadNote.updatedAt = new Date();
          return singleLeadNote;
        } else {
          return item;
        }
      });
      body = {
        notizen: filterdLeadNote,
        leadNotes: leadNotesArray,
      };
    }
    try {
      let resposne = await ApiPut(`vattenfall/updateTwoField?id=${id}`, body);
      setLeadNotesModel(false);
      setLeadShow("");
      getDataById();
      setSingleLeadNote("");
      toast.success("Eigene Notizen  aktualisiert");
      setModalType("");
    } catch (error) {
      console.log("error");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="notizen-plus-div-sc">
      <div className="notizen-plus-div-sc-model-background">
        <div className="notizen-plus-div-sc-model-main">
          <div className="notizen-plus-div-sc-model-main-icon">
            <label>
              {modalType === "leadNotes"
                ? "Eigene Notizen zum Kunden"
                : "Notizen zum Kunden"}
            </label>
            <i
              class="fa-sharp fa-solid fa-xmark"
              aria-hidden="true"
              onClick={() => {
                setLeadNotesModel(false);
                setLeadShow("");
              }}
              style={{ cursor: "pointer" }}
            ></i>
          </div>
          <textarea
            placeholder={
              modalType === "leadNotes"
                ? "Eigene Notizen zum Kunden...."
                : "Notizen zum Kunden....."
            }
            value={singleLeadNote?.text}
            onChange={(e) =>
              leadShow !== "show" &&
              setSingleLeadNote({ ...singleLeadNote, text: e.target.value })
            }
          ></textarea>

          {leadShow == "update" && (
            <div className="footer-button-alignment">
              <button onClick={() => updateLeadNotes()}>
                {loading ? (
                  <TailSpin color="#FFF" height={10} width={10} />
                ) : (
                  "Aktualisieren"
                )}
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default LeadNotesModel;
