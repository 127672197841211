import React, { useState } from "react";
import { Markers } from "./Markers";
import useSupercluster from "use-supercluster";
import GoogleMap from "google-maps-react-markers";
import { ChevronLeftIcon, ChevronRightIcon } from "@radix-ui/react-icons";
import "./MapComponent.scss";
import LeadCard from "../LeadsCards";
const OpenMarker = ({ items, setOpenMarker, openMarker }) => {
  const [index, setIndex] = useState(0);

  const onNext = () => {
    setIndex((prev) =>
      prev + 1 >= items.length ? items.length - 1 : prev + 1
    );
  };

  const onPrev = () => {
    setIndex((prev) => (prev - 1 < 0 ? 0 : prev - 1));
  };

  return (
    <div className="open-marker">
      <div className="grid-container">
        <LeadCard lead={items[index]} setOpenMarker={setOpenMarker} />

        <div className="navigation-controls">
          <div className="counter">{`${index + 1} Von ${items.length}`}</div>
          <button onClick={onPrev} className="control-button">
            <ChevronLeftIcon />
          </button>
          <button onClick={onNext} className="control-button">
            <ChevronRightIcon />
          </button>
        </div>
      </div>
    </div>
  );
};

export const MapComponent = ({
  center,
  classNameMap,
  leadsData,
  points,
  setOpenMarker,
  openMarker,
  setBounds,
  bounds,
  setZoom,
  zoom,
  map,
  setMap,
  searchLat,
}) => {
  const adjustedPoints = (() => {
    const coordinateMap = new Map();

    const data = leadsData?.forEach((f) => {
      const coordKey = `${f.locationCoordinates.coordinates[0]},${f.locationCoordinates.coordinates[1]}`;
      if (!coordinateMap.has(coordKey)) {
        coordinateMap.set(coordKey, []);
      }
      coordinateMap.get(coordKey).push(f);
    });

    const adjusted = [];
    const newdata = coordinateMap.forEach((points, key) => {
      if (points.length === 1) {
        adjusted.push({
          type: "Feature",
          properties: {
            id: points[0]?._id,
            ...points[0],
          },
          geometry: {
            type: "Point",
            coordinates: points[0].locationCoordinates.coordinates,
          },
        });
      } else {
        const data = points.forEach((point, index) => {
          const offset = index * 0.00001;
          const [lng, lat] = point.locationCoordinates.coordinates;
          adjusted.push({
            type: "Feature",
            properties: {
              id: point._id,
              ...point,
            },
            geometry: {
              type: "Point",
              coordinates: [lng + offset, lat + offset],
            },
          });
        });
      }
    });

    return adjusted;
  })();

  const { clusters, supercluster } = useSupercluster({
    zoom,
    bounds: bounds
      ? [
          bounds.getSouthWest().lng(),
          bounds.getSouthWest().lat(),
          bounds.getNorthEast().lng(),
          bounds.getNorthEast().lat(),
        ]
      : undefined,
    points: adjustedPoints,
    // leadsData?.map((f) => ({
    //   type: "Feature",
    //   properties: {
    //     id: f?._id,
    //     ...f,
    //   },
    //   pintype: f?.funnel_type === "straper" ? "staper" : "vattenfall",
    //   geometry: {
    //     type: "Point",
    //     coordinates: [
    //       f.locationCoordinates.coordinates[0],
    //       f.locationCoordinates.coordinates[1],
    //     ],
    //   },
    // })) ?? [],
    options: {
      radius: 130,
      maxZoom: 20,
      minZoom: 3,
    },
    disableRefresh: !points,
  });

  return (
    <div className={`map-container ${classNameMap}`}>
      <GoogleMap
        defaultZoom={zoom}
        defaultCenter={center}
        center={center}
        options={{
          disableDoubleClickZoom: !openMarker,
          zoom,
          center,
        }}
        onGoogleApiLoaded={({ map }) => {
          setMap(map);

          console.log("Map Has been loaded");
        }}
        apiKey={"AIzaSyBljmQF0NelnWV5WFKQuRqGtIFqERkPWX4"}
        events={[
          {
            name: "onClick",
            handler: () => {
              setOpenMarker(null);
              return true;
            },
          },
        ]}
        onChange={(e) => {
          setZoom(e.zoom);
          setBounds(e.bounds);
        }}
      >
        {clusters?.map((item) => {
          const leaves = item.id
            ? supercluster?.getLeaves(item.id, 500).map((f) => f.properties) ??
              []
            : [item.properties];

          return (
            <Markers
              lat={item.geometry.coordinates[1]}
              lng={item.geometry.coordinates[0]}
              count={leaves.length}
              type={
                leaves.length == 1
                  ? leaves[0]?.newVattenMapLead == true ||
                    leaves[0]?.newStraperMapLead == true
                    ? "orange"
                    : leaves[0]?.isDropDownLead == "dropdownLead"
                    ? "red"
                    : leaves[0]?.funnel_type === "straper"
                    ? "staper"
                    : "vattenfall"
                  : undefined
              }
              key={item.id}
              onOpenStateChanged={() => {
                setOpenMarker({
                  coordinates: item?.geometry?.coordinates,
                  items: leaves,
                });
                const data = map?.panTo({
                  lat: item?.geometry?.coordinates[1],
                  lng: item?.geometry?.coordinates[0],
                });
                return data;
              }}
            />
          );
        })}
        {openMarker && (
          <OpenMarker
            key={
              openMarker.items.map((item) => item.id).join("-") || "no-items"
            }
            lat={openMarker.coordinates[1]}
            lng={openMarker.coordinates[0]}
            items={openMarker.items}
            openMarker={openMarker}
            setOpenMarker={setOpenMarker}
          />
        )}
      </GoogleMap>
    </div>
  );
};
