import React, { useRef, useState } from "react";
import ReactGoogleAutocomplete from "react-google-autocomplete";
import "./googleAutocomplete.scss";

const GoogleAutocomplete = ({ setSearchLat, map, center }) => {
  const inputRef = useRef(null);

  const handlePlaceSelect = (place) => {
    setSearchLat({
      lat: place?.geometry?.location?.lat(),
      lng: place?.geometry?.location?.lng(),
    });

    let searchCenter = {
      lat: place?.geometry?.location?.lat(),
      lng: place?.geometry?.location?.lng(),
    };

    map.setCenter(searchCenter);
    map.setZoom(100);
  };

  const autocompleteProps = {
    apiKey: "AIzaSyBljmQF0NelnWV5WFKQuRqGtIFqERkPWX4",
    onPlaceSelected: handlePlaceSelect,
    options: {
      fields: ["ALL"],
      strictBounds: false,
      types: ["establishment", "geocode"],
    },
  };

  return (
    <div className="input" ref={inputRef}>
      <div className="new-map-div">
        <ReactGoogleAutocomplete
          {...autocompleteProps}
          placeholder={"Adresse wählen"}
          className="icon-input-alignment"
          style={{ background: "#E8EDF0" }}
          onChange={(e) => {
            if (!e.target.value) {
              map.setCenter(center);
              map.setZoom(6.25);
            }
          }}
        />
        <div style={{ zIndex: "99999999" }} ref={inputRef}></div>
      </div>
    </div>
  );
};

export default GoogleAutocomplete;
