import React, { useState } from "react";
import "./wattfoxmodel.scss";
import { useDropzone } from "react-dropzone";
import image1 from "../../../assets/icon/8242984.png";
import image2 from "../../../assets/icon/8243067.png";
import image3 from "../../../assets/icon/8243073.png";
import { ApiPost } from "../../../helpers/API/ApiData";
import { toast } from "react-toastify";
import Loader from "../Loader";

const Bulkuploadmodel = ({ type, text }) => {
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      const validFileTypes = [
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "text/csv",
      ];
      const filteredFiles = acceptedFiles.filter((file) =>
        validFileTypes.includes(file.type)
      );

      if (filteredFiles.length > 0) {
        setInputValue({ ...inputValue, uploadFile: filteredFiles[0] });
        setErrors({ ...errors, uploadFile: "" });
      } else {
        setErrors({
          ...errors,
          uploadFile: "Es sind nur CSV-, XLSX- und XLS-Dateien zulässig.",
        });
      }
    },
  });

  const [inputValue, setInputValue] = useState(null);
  const [errors, setErrors] = useState();
  const [loader, setLoader] = useState(false);

  const uploadPositivePostal = async () => {
    setLoader(true);
    const formData = new FormData();
    formData.append("excelFiles", inputValue?.uploadFile);
    try {
      if (type === "positivePostalModel") {
        await ApiPost(`documents/positiveExtraPLZ`, formData);
      } else if (type === "negativePostalModel") {
        await ApiPost(`documents/negativeFindPLZ`, formData);
      }
      toast.success("erfolgreich hochgeladen");
      setInputValue(null);
      setErrors();
    } catch (error) {
      console.log("error:", error);
    } finally {
      setLoader(false);
    }
  };
  return (
    <>
      {loader && <Loader />}
      <div className="bulkUploadImageMain">
        <h1>({text})</h1>
        {/* <h2>Hochladen einer Datei</h2>
        <p>
          Sie können Ihre Excel-Tabelle hochladen, um Ihren Dienst zu
          importieren
        </p> */}
        <div className="dragAndDrop" {...getRootProps()}>
          <input type="file" {...getInputProps()} />
          <div className="draganddrop-image-alignment">
            {!inputValue?.uploadFile ? (
              <>
                <div className="image-alignment">
                  <img src={image1} alt="image1" />
                  <img src={image2} alt="image2" />
                  <img src={image3} alt="image3" />
                </div>
              </>
            ) : (
              <span className="image-name">{inputValue?.uploadFile?.name}</span>
            )}
          </div>
        </div>

        <div>
          <span className="errors">{errors?.uploadFile}</span>
        </div>
        <div className="button-alignment-modal">
          <button
            className={!inputValue ? " upload-button" : "upload-button"}
            onClick={uploadPositivePostal}
            disabled={!inputValue}
          >
            Hochladen
          </button>
        </div>
      </div>
    </>
  );
};

export default Bulkuploadmodel;
