export const statusOption = [
  {
    value: "offen",
    label: "offen",
  },
  {
    value: "AUFMAß",
    label: "AUFMAß",
  },
  {
    value: "ZEITLICHE VERZÖGERUNG",
    label: "ZEITLICHE VERZÖGERUNG",
  },
  {
    value: "SPÄTER ANRUFEN",
    label: "SPÄTER ANRUFEN",
  },
  {
    value: "STORNO",
    label: "STORNO",
  },
  {
    value: "ANGEBOTSBESPRECHUNG",
    label: "ANGEBOTSBESPRECHUNG",
  },
  {
    value: "ANGEBOTSBESPRECHUNG 2",
    label: "ANGEBOTSBESPRECHUNG 2",
  },
  {
    value: "VERKAUFT",
    label: "VERKAUFT",
  },
  {
    value: "NICHT ERREICHT",
    label: "NICHT ERREICHT",
  },
  {
    value: "TERMINIERT (1)",
    label: "TERMINIERT (1)",
  },
  {
    value: "TERMINIERT (2)",
    label: "TERMINIERT (2)",
  },
  {
    value: null,
    label: "Null",
  },
];

export const assignLeadOption = [
  {
    value: true,
    label: "zugeteilt",
  },
  {
    value: false,
    label: "nicht zugeteilt",
  },
];

export  const ProjectStyle = {
    control: (provided, state) => ({
      ...provided,
      maxWidth: "150px",
      fontSize: "12px",
      marginLeft: "30px",
      border: state.isFocused ? "2px solid #73AA85 !important" : "",
      boxShadow: "1px solid #73AA85 !important",
    }),
    option: (provided, state) => ({
      ...provided,
      fontSize: "13px",
      color: state.isSelected ? "white" : "black", // Change selected color here
      backgroundColor: state.isSelected ? "#73AA85" : "white",
      "&:hover": {
        backgroundColor: "#73AA85",
      },
    }),
    menu: (provided) => ({
      ...provided,
      maxWidth: "150px",
      marginLeft: "30px",
      zIndex: "999",
    }),
  };