import React, { useEffect, useMemo, useRef, useState } from "react";
import { MapComponent } from "./MapComponent";
import useDebounce from "../../../hooks/useDebounceHook";
import { ApiGet, ApiPost } from "../../../helpers/API/ApiData";
import Select from "react-select";
import { toast } from "react-toastify";
import { ProjectStyle } from "./constants";
import GoogleAutocomplete from "./googleAutocomplete";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./map.scss";

const GoogleMap = () => {
  const center = { lat: 51.03447636760166, lng: 11.8386485625 };

  const [searchValue, setSearchValue] = useState("");
  const [LeadSources, setLeadSources] = useState();
  const [bounds, setBounds] = useState();
  const [leadsData, setLeadsData] = useState([]);
  const [zoom, setZoom] = useState(6.25);
  const [openMarker, setOpenMarker] = useState(null);
  const [leadStatus, setLeadStatus] = useState([]);
  const [AllUser, setAllUser] = useState([]);
  const [Users, setUsers] = useState([]);
  const [searchLat, setSearchLat] = useState({ lat: "", lng: "" });
  const inputRef = useRef(null);
  const [map, setMap] = useState(null);
  const searchDebaounce = useDebounce(searchValue, 400);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const ALL_STATUS = [
    { value: "11", label: "offen" },
    { value: "20", label: "NICHT ERREICHT" },
    { value: "21", label: "TERMINIERT (1)" },
    { value: "25", label: "TERMINIERT (2)" },
    { value: "26", label: "TERMINIERT (3)" },
    { value: "12", label: "ZEITLICHE VERZÖGERUNG" },
    { value: "12", label: "SPÄTER ANRUFEN" },
    { value: "13", label: "AUFMAß" },
    { value: "14", label: "ANGEBOTSBESPRECHUNG" },
    { value: "15", label: "ANGEBOTSBESPRECHUNG 2" },
    { value: "16", label: "ABSAGE" },
    { value: "17", label: "VERKAUFT" },
    { value: "19", label: "STORNO" },
    { value: "20", label: "Open" },
    { value: "27", label: "Confirmed" },
    { value: "28", label: "Rejected" },
  ];

  const handleChangeAssign = (data, type) => {
    switch (type) {
      case "LeadSource":
        setLeadSources(data);
        break;
      case "status":
        setLeadStatus(data);
        break;
      case "user":
        setUsers(data);
        break;
    }
  };

  const LeadSourceArray = [
    {
      value: "straper",
      label: "Wattfox",
    },
    {
      value: "vattenfall",
      label: "Vattenfall",
    },
  ];

  const boundedDetails = useMemo(() => {
    return {
      West: bounds?.getSouthWest()?.lng(),
      South: bounds?.getSouthWest()?.lat(),
      East: bounds?.getNorthEast()?.lng(),
      North: bounds?.getNorthEast()?.lat(),
    };
  }, [bounds]);
  const debouncedSearchValue = useDebounce(boundedDetails, 1000);
  // const handleGetDashboardCount = async () => {
  //   const mappedUser = Users?.map((item) => item.value);
  //   const leadStatusMap = leadStatus?.map((item) => item.label);
  //   const body = {
  //     benutzerId: !!Users.length ? mappedUser : [],
  //     leadStatus: !!leadStatus.length ? leadStatusMap : [],
  //   };
  //   await ApiPost(
  //     startDate && endDate
  //       ? `vattenfall/getMultiLocationLead?West=${
  //           debouncedSearchValue?.West
  //         }&South=${debouncedSearchValue?.South}&East=${
  //           debouncedSearchValue?.East
  //         }&North=${debouncedSearchValue?.North}${
  //           searchDebaounce ? `&search=${searchDebaounce}` : ""
  //         }${LeadSources ? `&type=${LeadSources.value}` : ""}${
  //           startDate && endDate
  //             ? `&startDate=${startDate?.toISOString().split("T")[0]}`
  //             : ""
  //         }${endDate ? `&endDate=${endDate?.toISOString().split("T")[0]}` : ""}`
  //       : `vattenfall/getMultiLocationLead?West=${
  //           debouncedSearchValue?.West
  //         }&South=${debouncedSearchValue?.South}&East=${
  //           debouncedSearchValue?.East
  //         }&North=${debouncedSearchValue?.North}${
  //           searchDebaounce ? `&search=${searchDebaounce}` : ""
  //         }`,
  //     body
  //   )
  //     .then((response) => {
  //       const data = response?.data?.payload;
  //       setLeadsData([...data?.vattenfallLead, ...data?.staperLead]);
  //     })
  //     .catch((error) => {
  //       toast.error(error);
  //     });
  // };
  // useEffect(() => {
  //   if (
  //     boundedDetails &&
  //     boundedDetails?.West &&
  //     boundedDetails?.South &&
  //     boundedDetails?.East &&
  //     boundedDetails?.North
  //   ) {
  //     handleGetDashboardCount();
  //   }
  // }, [
  //   debouncedSearchValue,
  //   searchDebaounce,
  //   LeadSources,
  //   Users,
  //   leadStatus,
  //   startDate,
  //   endDate,
  // ]);

  const handleGetDashboardCount = async () => {
    const mappedUser = Users?.map((item) => item.value);
    const leadStatusMap = leadStatus?.map((item) => item.label);
    const body = {
      benutzerId: !!Users?.length ? mappedUser : [],
      leadStatus: !!leadStatus?.length ? leadStatusMap : [],
    };

    let url = `vattenfall/getMultiLocationLead?West=${
      debouncedSearchValue?.West || ""
    }&South=${debouncedSearchValue?.South || ""}&East=${
      debouncedSearchValue?.East || ""
    }&North=${debouncedSearchValue?.North || ""}`;

    if (searchDebaounce) {
      url += `&search=${searchDebaounce}`;
    }

    if (LeadSources) {
      url += `&type=${LeadSources.value}`;
    }

    const formatDate = (date) => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    };

    if (startDate && endDate) {
      url += `&startDate=${formatDate(startDate)}`;
      url += `&endDate=${formatDate(endDate)}`;
    } else if (startDate || endDate) {
      return;
    }

    await ApiPost(url, body)
      .then((response) => {
        const data = response?.data?.payload;
        setLeadsData([...data?.vattenfallLead, ...data?.staperLead]);
      })
      .catch((error) => {
        toast.error(error.message || "Something went wrong.");
      });
  };

  useEffect(() => {
    if (
      boundedDetails &&
      boundedDetails?.West &&
      boundedDetails?.South &&
      boundedDetails?.East &&
      boundedDetails?.North
    ) {
      handleGetDashboardCount();
    }
  }, [
    debouncedSearchValue,
    searchDebaounce,
    LeadSources,
    Users,
    leadStatus,
    startDate,
    endDate,
  ]);

  const getVertisebPartner = async () => {
    try {
      let resp = await ApiGet(`user/getAllUser`);

      const data = resp?.data?.payload?.findAllUser;
      const mappedData = data?.map((item) => {
        const obj = {
          value: item?._id,
          label: item?.name,
        };
        return obj;
      });
      setAllUser(mappedData);
    } catch (error) {
      console.error("error:", error);
    }
  };

  useEffect(() => {
    getVertisebPartner();
  }, []);

  const newhandleOnNewChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  return (
    <div className="card p-1">
      <div className="p-2 mb-2">
        <div className="row mb-4 px-3 ">
          <div className="alignment-class">
            <div>
              <h2 className="pt-2 mr-5">Heatmap</h2>
            </div>

            <div className="row  mobile-view-alignment-main">
              <div>
                <div className="project-Dropdown project-Dropdown-sc">
                  <Select
                    className="Select-main"
                    value={LeadSources}
                    onChange={(e) => handleChangeAssign(e, "LeadSource")}
                    options={LeadSourceArray}
                    styles={ProjectStyle}
                    placeholder="Leadquelle"
                    isClearable={true}
                  />
                </div>
              </div>
              <div>
                <div className="project-Dropdown project-Dropdown-sc">
                  <Select
                    className="Select-main"
                    value={leadStatus}
                    onChange={(e) => handleChangeAssign(e, "status")}
                    options={ALL_STATUS}
                    styles={ProjectStyle}
                    placeholder="Status"
                    isMulti={true}
                    isClearable={true}
                  />
                </div>
              </div>
              <div>
                <div className="project-Dropdown project-Dropdown-sc">
                  <Select
                    className="Select-main"
                    value={Users}
                    onChange={(e) => handleChangeAssign(e, "user")}
                    options={AllUser}
                    styles={ProjectStyle}
                    placeholder="Vertriebspartner"
                    isMulti={true}
                    isClearable={true}
                  />
                </div>
              </div>

              <div>
                <div>
                  <input
                    type="search"
                    className={`form-control form-control-lg form-control-solid `}
                    onChange={(e) => {
                      setSearchValue(e.target.value);
                    }}
                    name="Suche"
                    value={searchValue}
                    placeholder="Suche"
                  />
                </div>
              </div>

              {map && (
                <div>
                  <GoogleAutocomplete
                    setSearchLat={setSearchLat}
                    map={map}
                    center={center}
                    zoom={zoom}
                    setZoom={setZoom}
                  />
                </div>
              )}

              <div className="date-picker">
                <DatePicker
                  selected={startDate}
                  startDate={startDate}
                  endDate={endDate}
                  placeholderText="Date"
                  onChange={(e) => newhandleOnNewChange(e)}
                  onKeyDown={(e) => e.preventDefault()}
                  maxDate={new Date()}
                  selectsRange
                  dateFormat="dd/MM/yyyy"
                  className="custom-date"
                  isClearable
                />
              </div>
            </div>
          </div>
        </div>
        <MapComponent
          searchLat={searchLat}
          marker={center}
          // center={center}
          center={searchLat?.lat ? searchLat : center}
          setOpenMarker={setOpenMarker}
          openMarker={openMarker}
          setBounds={setBounds}
          setZoom={setZoom}
          leadsData={leadsData}
          zoom={zoom}
          bounds={bounds}
          setMap={setMap}
          map={map}
        />
      </div>
    </div>
  );
};

export default GoogleMap;
