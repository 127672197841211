import React from "react";
import "./wattfoxmodel.scss";
import Bulkuploadmodel from "../../components/BulkUpload/bulkuploadmodel";

const WattFoxDataModel = () => {
  return (
    <div className="bulkmdel-alignment">
      <Bulkuploadmodel
        type={"positivePostalModel"}
        text={"Positive PostalCode"}
      />
      <Bulkuploadmodel
        type={"negativePostalModel"}
        text={"Negative PostalCode"}
      />
    </div>
  );
};

export default WattFoxDataModel;
